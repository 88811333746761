import * as TYPES from "./auditActionTypes";

const defaultState = {
  count: 0,
  audits: [],
  auditBusy: false
};

export default function audit(state = defaultState, action) {
  switch (action.type) {
    case TYPES.AUDIT_COUNT:
      return {
        ...state,
        count: action.value
      };
    case TYPES.AUDITS:
      return {
        ...state,
        audits: action.value
      };
    case TYPES.BUSY_AUDIT:
      return {
        ...state,
        auditBusy: action.value
      };
    default:
      return state;
  }
}
