
import * as TYPES from "./paycheckActionTypes";

const defaultState = {
  count: 0,
  paychecks: [],
  selectedPaycheck: {},
  busyPaycheck: false
};

export default function psycheck(state = defaultState, action) {
  switch (action.type) {
    case TYPES.PAYCHECKS:
      return {
        ...state,
        paychecks: action.value
      };
    case TYPES.PAYCHECK_COUNT:
      return {
        ...state,
        count: action.value
      };
    case TYPES.PAYCHECK_SELECTED:
      return {
        ...state,
        selectedPaycheck: action.value
      };
    case TYPES.PAYCHECK_BUSY:
      return {
        ...state,
        busyPaycheck: action.value
      };
    default:
      return state;
  }
}
