import * as TYPES from "./collabActionTypes";

const defaultState = {
  count: 0,
  emails: []
};

export default function emailer(state = defaultState, action) {
  switch (action.type) {
    case TYPES.ALL_PROVIDERS:
      return {
        ...state,
        providers: action.value
      };
    case TYPES.ALL_PROVIDERS_EB:
      return {
        ...state,
        eldBooks: action.value
      };
    case TYPES.ALL_PROVIDERS_SM:
      return {
        ...state,
        smasara: action.value
      };
    case TYPES.ALL_PROVIDERS_KT:
      return {
        ...state,
        keeptruckin: action.value
      };
    case TYPES.ALL_PROVIDERS_SE:
      return {
        ...state,
        smartelds: action.value
      };
    default:
      return state;
  }
}
