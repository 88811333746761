import * as TYPES from "./vendorActionTypes";

const defaultState = {
  //VENDOR: {}
};

export default function business(state = defaultState, action) {
  switch (action.type) {
    case TYPES.VENDORS:
      return {
        ...state,
        vendors: action.value
      };
    case TYPES.VENDOR:
      return {
        ...state,
        vendor: action.value
      };
    case TYPES.VENDOR_COUNT:
      return {
        ...state,
        count: action.value
      };
    default:
      return state
  }
}
