import * as TYPES from "./invoiceActionTypes";

const defaultState = {
  count: 0,
  invoices: []
};

export default function invoice(state = defaultState, action) {
  switch (action.type) {
    case TYPES.ALL_INVOICES:
      return {
        ...state,
        invoices: action.value
      };
    case TYPES.ALL_INVOICES_COUNT:
      return {
        ...state,
        count: action.value
      };
    default:
      return state;
  }
}
