import * as TYPES from "./firebaseActionTypes";

const defaultState = {
  messages: []
};

export default function firebaseData(state = defaultState, action) {
  switch (action.type) {
    case TYPES.PUSH_MESSAGES:
      return {
        ...state,
        firebase: action.value
      };
    default:
      return state;
  }
}
