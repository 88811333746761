
import * as TYPES from "./locationActionTypes";

const defaultState = {
  count: 0,
  locations: []
};

export default function dispatch(state = defaultState, action) {
  switch (action.type) {
    case TYPES.LOCATION_COUNT:
      return {
        ...state,
        count: action.value
      };
    case TYPES.LOCATIONS:
      return {
        ...state,
        locations: action.value
      };
    default:
      return state;
  }
}
