import * as TYPES from "./trackingActionTypes";

const defaultState = {
  count: 0,
  trackings: [],
  selectedTracking: {},
  trackingUpdates: [],
  trackingStatuses: []
};

export default function tracking(state = defaultState, action) {
  switch (action.type) {
    case TYPES.SEL_TRACKING:
      return {
        ...state,
        selectedTracking: action.value
      };
    case TYPES.ALL_TRACKINGS:
      return {
        ...state,
        trackings: action.value
      };
    case TYPES.ALL_TRACKING_UPDATES:
      return {
        ...state,
        trackingUpdates: action.value
      };
    case TYPES.ALL_TRACKING_STATUSES:
      return {
        ...state,
        trackingStatuses: action.value
      };
    case TYPES.ALL_TRACKINGS_COUNT:
      return {
        ...state,
        count: action.value
      };
    default:
      return state;
  }
}
