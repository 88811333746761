import * as TYPES from "./weatherActionTypes";

const defaultState = {
  loading: false
};

export default function weather(state = defaultState, action) {
  switch (action.type) {
    case TYPES.LOADING_WEATHER:
      return {
        ...state,
        loading: action.value
      };
    default:
      return state;
  }
}
