import * as TYPES from "./creditLimitActionTypes";

const defaultState = {
  TODO: {}
};

export default function business(state = defaultState, action) {
  switch (action.type) {
    case TYPES.CREDITLIMTS:
      return {
        ...state,
        creditLimits: action.value
      };
    case TYPES.CREDITLIMT_COUNT:
      return {
        ...state,
        count: action.value
      };
    default:
      return state
  }
}
