import * as TYPES from "./emailerActionTypes";

const defaultState = {
  count: 0,
  emails: []
};

export default function emailer(state = defaultState, action) {
  switch (action.type) {
    case TYPES.ALL_EMAILS:
      return {
        ...state,
        emails: action.value
      };
    case TYPES.ALL_EMAILS_COUNT:
      return {
        ...state,
        count: action.value
      };
    default:
      return state;
  }
}
