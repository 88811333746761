
import * as TYPES from "./expenseActionTypes";

const defaultState = {
  count: 0,
  expenses: [],
  selectedExpense: {}
};

export default function expense(state = defaultState, action) {
  switch (action.type) {
    case TYPES.EXPENSES:
      return {
        ...state,
        expenses: action.value
      };
    case TYPES.EXPENSE_COUNT:
      return {
        ...state,
        count: action.value
      };
    case TYPES.EXPENSE_SELECTED:
      return {
        ...state,
        selectedExpense: action.value
      };
    default:
      return state;
  }
}
