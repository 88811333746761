import * as TYPES from "./inventoryActionTypes";

const defaultState = {
  inventories: [],
  count: 0
};

export default function inventory(state = defaultState, action) {
  switch (action.type) {
    case TYPES.INVENTORY:
      return {
        ...state,
        inventories: action.value
      };
    case TYPES.INVENTORY_COUNT:
      return {
        ...state,
        count: action.value
      };
    default:
      return state
  }
}
