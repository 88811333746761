
import * as TYPES from "./tripSheetActionTypes";

const defaultState = {
  count: 0,
  trips: []
};

export default function trip(state = defaultState, action) {
  switch (action.type) {
    case TYPES.TRIPS:
      return {
        ...state,
        trips: action.value
      };
    case TYPES.COUNT_TRIPS:
      return {
        ...state,
        count: action.value
      };
    default:
      return state;
  }
}
