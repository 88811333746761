import * as TYPES from "./notificationActionTypes";

const defaultState = {
  //  TODO: {}
};

export default function business(state = defaultState, action) {
  switch (action.type) {
    case TYPES.NOTIFICATIONS:
      return {
        ...state,
        notifications: action.value
      };
    case TYPES.NOTIFICATION_COUNT:
      return {
        ...state,
        count: action.value
      };
    default:
      return state
  }
}
