import * as TYPES from "./workLanesActionTypes";

const defaultState = {
  //  TODO: {}
};

export default function business(state = defaultState, action) {
  switch (action.type) {
    case TYPES.WORK_LANES:
      return {
        ...state,
        workLanes: action.value
      };
    case TYPES.WORK_LANES_COUNT:
      return {
        ...state,
        count: action.value
      };
    default:
      return state
  }
}
