import * as TYPES from "./openActionTypes";

const defaultState = {
  users: [],
};

export default function open(state = defaultState, action) {
  switch (action.type) {
    case TYPES.USERS:
      return {
        ...state,
        users: action.value,
      };
    default:
      return state;
  }
}
