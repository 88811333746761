
import * as TYPES from "./eldbooksActionTypes";

const defaultState = {
  drivers: [],
  vehicles: []
};

export default function eldbooks(state = defaultState, action) {
  switch (action.type) {
    case TYPES.ALL_ELD_VT:
      return {
        ...state,
        allElds: action.value
      };
    case TYPES.ELD_VT_TEST:
      return {
        ...state,
        test: action.value
      };
    case TYPES.ELD_VT_VEHICLES:
      return {
        ...state,
        vehicles: action.value
      };
    case TYPES.ELD_VT_DRIVERS:
      return {
        ...state,
        drivers: action.value
      };
    default:
      return state;
  }
}
