
import * as TYPES from "./feedbackActionTypes";

const defaultState = {
  count: 0,
  feedbacks: []
};

export default function feedback(state = defaultState, action) {
  switch (action.type) {
    case TYPES.FEEDBACKS:
      return {
        ...state,
        feedbacks: action.value
      };
    case TYPES.FEEDBACK_COUNT:
      return {
        ...state,
        count: action.value
      };
    case TYPES.FEEDBACK_SEL:
      return {
        ...state,
        selectedFeedback: action.value
      };
    default:
      return state;
  }
}
