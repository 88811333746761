import * as TYPES from "./maintenanceActionTypes";

const defaultState = {
  maintenances: [],
  count: 0
};

export default function maintenance(state = defaultState, action) {
  switch (action.type) {
    case TYPES.MAINTENANCE:
      return {
        ...state,
        maintenances: action.value
      };
    case TYPES.MAINTENANCE_COUNT:
      return {
        ...state,
        count: action.value
      };
    default:
      return state
  }
}
