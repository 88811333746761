export const COMPANY_COUNT = "COMPANY_COUNT";
export const COMPANIES = "COMPANIES";
export const BROKERS = "BROKERS";
export const BUSY_COMPANY = "BUSY_COMPANY";
export const ADD_COMPANY_BUSY = "ADD_COMPANY_BUSY";
export const SELECTED_COMPANY = "SELECTED_COMPANY";

export const BROKERS_COUNT = "BROKERS_COUNT";
export const ALL_BROKERS = "ALL_BROKERS";

export const BLACKLIST_COUNT = "BLACKLIST_COUNT";
export const ALL_BLACKLISTEDS = "ALL_BLACKLISTEDS";
