
import * as TYPES from "./keepTruckingActionTypes";

const defaultState = {
  test: {},
  allElds: [],
  drivers: [],
  vehicles: []
};

export default function keepTrucking(state = defaultState, action) {
  switch (action.type) {
    case TYPES.ALL_ELD_KT:
      return {
        ...state,
        allElds: action.value
      };
    case TYPES.ELD_KT_TEST:
      return {
        ...state,
        test: action.value
      };
    case TYPES.ELD_KT_VEHICLES:
      return {
        ...state,
        vehicles: action.value
      };
    case TYPES.ELD_KT_DRIVERS:
      return {
        ...state,
        drivers: action.value
      };
    default:
      return state;
  }
}
