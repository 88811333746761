
import * as TYPES from "./dashboardActionTypes";

const defaultState = {
  loadsAll: [],
  payStatus: {},
  loadStatus: {},
  invoiceStatus: {},
  expenseStatus: {},
  loadChart: {}
};

export default function dashboard(state = defaultState, action) {
  switch (action.type) {
    case TYPES.ALL_LOADS:
      return {
        ...state,
        loadsAll: action.value
      };
    case TYPES.ALL_LOAD_STATUS:
      return {
        ...state,
        loadStatus: action.value
      };
    case TYPES.ALL_LOAD_GROSS:
      return {
        ...state,
        loadGross: action.value
      };
    case TYPES.ALL_INVOICE_STATUS:
      return {
        ...state,
        invoiceStatus: action.value
      };
    case TYPES.ALL_PAY_STATUS:
      return {
        ...state,
        payStatus: action.value
      };
    case TYPES.ALL_EXPENSE_STATUS:
      return {
        ...state,
        expenseStatus: action.value
      };
    case TYPES.ALL_LOAD_TRUCK_STATUS:
      return {
        ...state,
        loadTruckStatus: action.value
      };
    case TYPES.ALL_LOAD_CHART:
      return {
        ...state,
        loadChart: action.value
      };
    default:
      return state;
  }
}
