
import * as TYPES from "./intuitActionTypes";

const defaultState = {
  authUrl: '',
  business: {}
};

export default function eldbooks(state = defaultState, action) {
  switch (action.type) {
    case TYPES.INTUIT_AUTH_URL:
      return {
        ...state,
        authUrl: action.value
      };
    case TYPES.INTUIT_LOADING:
      return {
        ...state,
        business: action.value,
      };
    case TYPES.INTUIT_BUSINESS:
      return {
        ...state,
        business: action.value
      };
    default:
      return state;
  }
}
