
import * as TYPES from "./companyActionTypes";

const defaultState = {
  count: 0,
  companies: [],
  compnyBusy: false,
  selectedCompany: {},
  brokerCount: 0,
  allBrokers: 0
};

export default function company(state = defaultState, action) {
  switch (action.type) {
    case TYPES.COMPANY_COUNT:
      return {
        ...state,
        count: action.value
      };
    case TYPES.COMPANIES:
      return {
        ...state,
        companies: action.value
      };
    case TYPES.SELECTED_COMPANY:
      return {
        ...state,
        selectedCompany: action.value
      };
    case TYPES.BUSY_COMPANY:
      return {
        ...state,
        companyBusy: action.value
      };
    case TYPES.BROKERS:
      return {
        ...state,
        brokers: action.value
      };
    case TYPES.BROKERS_COUNT:
      return {
        ...state,
        brokerCount: action.value
      };
    case TYPES.ALL_BROKERS:
      return {
        ...state,
        allBrokers: action.value
      };
    case TYPES.BLACKLIST_COUNT:
      return {
        ...state,
        blacklistedCount: action.value
      };
    case TYPES.ALL_BLACKLISTEDS:
      return {
        ...state,
        allBlacklisteds: action.value
      };
    default:
      return state;
  }
}
