export const DUMPS = "DUMPS";
export const DUMP_COUNT = "DUMP_COUNT";
export const DUMP_BUSY = "DUMP_BUSY";
export const SEL_DUMP = "SEL_DUMP";
export const DUMP_DISPATCHES = "DUMP_DISPATCHES";
export const DUMP_DISPATCHES_COUNT = "DUMP_DISPATCHES_COUNT";
export const DUMP_RATE = "DUMP_RATE";
export const DUMP_PAYABLE_COUNT = "DUMP_PAYABLE_COUNT";
export const DUMP_PAYABLES = "DUMP_PAYABLES";
export const DUMP_RECEIVABLE_COUNT = "DUMP_RECEIVABLE_COUNT";
export const DUMP_RECEIVABLES = "DUMP_RECEIVABLES";
