
import * as TYPES from "./smActionTypes";

const defaultState = {
  drivers: [],
  vehicles: []
};

export default function sm(state = defaultState, action) {
  switch (action.type) {
    case TYPES.ALL_ELD_SM:
      return {
        ...state,
        allElds: action.value
      };
    case TYPES.ELD_SM_TEST:
      return {
        ...state,
        test: action.value
      };
    case TYPES.ELD_SM_VEHICLES:
      return {
        ...state,
        vehicles: action.value
      };
    case TYPES.ELD_SM_DRIVERS:
      return {
        ...state,
        drivers: action.value
      };
    default:
      return state;
  }
}
