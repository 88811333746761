
import * as TYPES from "./driverActionTypes";

const defaultState = {
  count: 0,
  drivers: [],
  selectedDriver: {}
};

export default function driver(state = defaultState, action) {
  switch (action.type) {
    case TYPES.DRIVERS:
      return {
        ...state,
        drivers: action.value
      };
    case TYPES.COUNT_DRIVER:
      return {
        ...state,
        count: action.value
      };
    case TYPES.SELECTED_DRIVER:
      return {
        ...state,
        selectedDriver: action.value
      };
    case TYPES.TRIPS:
      return {
        ...state,
        trips: action.value
      };
    case TYPES.COUNT_TRIPS:
      return {
        ...state,
        tripCount: action.value
      };
    default:
      return state;
  }
}
