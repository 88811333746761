
import * as TYPES from "./addressActionTypes";

const defaultState = {
  count: 0,
  addresses: [],
  addressBusy: false
};

export default function address(state = defaultState, action) {
  switch (action.type) {
    case TYPES.ADDRESS_COUNT:
      return {
        ...state,
        count: action.value
      };
    case TYPES.ADDRESSES:
      return {
        ...state,
        addresses: action.value
      };
    case TYPES.ADDRESS_BUSY:
      return {
        ...state,
        addressBusy: action.value
      };
    default:
      return state;
  }
}
