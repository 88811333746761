import * as TYPES from "./repairActionTypes";

const defaultState = {
  //REPAIR: {}
};

export default function business(state = defaultState, action) {
  switch (action.type) {
    case TYPES.REPAIRS:
      return {
        ...state,
        repairs: action.value
      };
    case TYPES.REPAIR_COUNT:
      return {
        ...state,
        count: action.value
      };
    default:
      return state
  }
}
