import * as TYPES from "./estimatesActionTypes";

const defaultState = {
  estimates: [],
  estimatesCount: 0
};

export default function todo(state = defaultState, action) {
  switch (action.type) {
    case TYPES.ESTIMATES:
      return {
        ...state,
        estimates: action.value
      };
    case TYPES.ESTIMATES_COUNT:
      return {
        ...state,
        estimatesCount: action.value
      };
    default:
      return state
  }
}
