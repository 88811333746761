
import * as TYPES from "./documentActionTypes";

const defaultState = {
  count: 0,
  documents: [],
  selectedDocument: {},
  busyDocument: false
};

export default function psycheck(state = defaultState, action) {
  switch (action.type) {
    case TYPES.DOCUMENTS:
      return {
        ...state,
        documents: action.value
      };
    case TYPES.DOCUMENTS_COUNT:
      return {
        ...state,
        count: action.value
      };
    case TYPES.DOCUMENT_SELECTED:
      return {
        ...state,
        selectedDocument: action.value
      };
    case TYPES.DOCUMENT_BUSY:
      return {
        ...state,
        busyDocument: action.value
      };
    default:
      return state;
  }
}
