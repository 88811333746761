import * as TYPES from "./incomeActionTypes";

const defaultState = {
  //  TODO: {}
};

export default function business(state = defaultState, action) {
  switch (action.type) {
    case TYPES.INCOME:
      return {
        ...state,
        incomes: action.value
      };
    case TYPES.INCOME_COUNT:
      return {
        ...state,
        count: action.value
      };
    default:
      return state
  }
}
