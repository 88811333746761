import * as TYPES from "./searchActionTypes";

const defaultState = {
  carriers: []
};

export default function search(state = defaultState, action) {
  switch (action.type) {
    case TYPES.SEARCH_CARRIER:
      return {
        ...state,
        carriers: action.value
      };
    default:
      return state;
  }
}
