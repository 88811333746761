import * as TYPES from "./integrationActionTypes";

const defaultState = {
  count: 0,
  integrations: [],
};

export default function integration(state = defaultState, action) {
  switch (action.type) {
    case TYPES.INTEGRATION_COUNT:
      return {
        ...state,
        count: action.value,
      };
    case TYPES.INTEGRATIONS:
      return {
        ...state,
        integrations: action.value,
      };
    default:
      return state;
  }
}
