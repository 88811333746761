const path = require("path");
// const ENV = process.env.NODE_ENV;
// const swPath =
//   ENV === "production"
//     ? "./firebase-messaging-sw.js"
//     : "./firebase-messaging-sw-dev.js";
const swFile = "firebase-messaging-sw.js";

const registerServiceWorker = () => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register(path.resolve(__dirname, "", swFile))
      .then(function (registration) {
        console.log("Registration successful, scope is:", registration.scope);
      })
      .catch(function (err) {
        console.log("Service worker registration failed, error:", err);
      });
  }
};

export {registerServiceWorker};
