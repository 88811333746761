import * as TYPES from "./pushNotificationActionTypes";

const defaultState = {
  notifications: []
};

export default function business(state = defaultState, action) {
  switch (action.type) {
    case TYPES.PUSH_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.value
      };
    default:
      return state;
  }
}
