import axios from "axios";
import _get from "lodash/get";

export const setIP = value => ({
  type: "SET_IP",
  value
});

export const setCords = value => ({
  type: "COORDINATES",
  value
});

export function getCords() {
  return async (dispatch, getState) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(response => {
        const latitude = _get(response, "coords.latitude");
        const longitude = _get(response, "coords.longitude");
        if (longitude && latitude) {
          dispatch(setCords({ latitude, longitude }));
        }
      });
    }
  };
}

export function getIp() {
  return async (dispatch, getState) => {
    try {
      const url = "https://api.ipify.org/?format=json";
      const _response = await axios.get(url);

      let response = _get(_response, "data.ip", "");

      dispatch(setIP(response));
      dispatch(getCords());

      return response;
    } catch (error) {
      return null;
    }
  };
}
