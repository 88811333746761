
import * as TYPES from "./actionTypes";

const defaultState = {
  count: 0,
  dumps: [],
  busy: false,
  selDump: {},
  dumpRate: {},
  dumpDispatches: [],
  dumpDispatchCount: 0,
  selDumpDispatches: [],
  dumpPayablesCount: 0,
  dumpPayables: [],
  dumpReceivalesCount: 0,
  dumpReceivales: []
};

export default function dumpDispatch(state = defaultState, action) {
  switch (action.type) {
    case TYPES.DUMPS:
      return {
        ...state,
        dumps: action.value
      };
    case TYPES.DUMP_COUNT:
      return {
        ...state,
        count: action.value
      };
    case TYPES.DUMP_BUSY:
      return {
        ...state,
        busy: action.value
      };
    case TYPES.SEL_DUMP:
      return {
        ...state,
        selDump: action.value
      };
    case TYPES.DUMP_DISPATCHES:
      return {
        ...state,
        dumpDispatches: action.value
      };
    case TYPES.DUMP_DISPATCHES_COUNT :
      return {
        ...state,
        dumpDispatchCount: action.value
      };
    case TYPES.DUMP_RATE:
      return {
        ...state,
        dumpRate: action.value
      };
    case TYPES.DUMP_PAYABLE_COUNT:
      return {
        ...state,
        dumpPayablesCount: action.value
      };
    case TYPES.DUMP_PAYABLES:
      return {
        ...state,
        dumpPayables: action.value
      };
    case TYPES.DUMP_RECEIVABLE_COUNT:
      return {
        ...state,
        dumpReceivalesCount: action.value
      };
    case TYPES.DUMP_RECEIVABLES:
      return {
        ...state,
        dumpReceivales: action.value
      };
    default:
      return state;
  }
}
