
import * as TYPES from "./contactActionTypes";

const defaultState = {
  contacts: []
};

export default function company(state = defaultState, action) {
  switch (action.type) {
    case TYPES.CONTACTS:
      return {
        ...state,
        contacts: action.value
      };
    case TYPES.CONTACT_COUNT:
      return {
        ...state,
        count: action.value
      };
    default:
      return state;
  }
}
