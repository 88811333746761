
import * as TYPES from "./baccountActionTypes";

const defaultState = {
  count: 0,
  baccounts: [],
  baccountBusy: false,
  selectedPaccount: {},
  countChecks: 0,
  printChecks: []
};

export default function baccount(state = defaultState, action) {
  switch (action.type) {
    case TYPES.BACCOUNT_COUNT:
      return {
        ...state,
        count: action.value
      };
    case TYPES.BACCOUNTS:
      return {
        ...state,
        baccounts: action.value
      };
    case TYPES.SELECTED_BACCOUNT:
      return {
        ...state,
        selectedPaccount: action.value
      };
    case TYPES.BUSY_BACCOUNT:
      return {
        ...state,
        baccountBusy: action.value
      };
    case TYPES.PRINT_CHECKS:
      return {
        ...state,
        printChecks: action.value
      };
    case TYPES.PRINT_CHECKS_COUNT:
      return {
        ...state,
        countChecks: action.value
      };
    default:
      return state;
  }
}
