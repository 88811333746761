
import * as TYPES from "./timeSheetActionTypes";

const defaultState = {
  count: 0,
  timeSheets: []
};

export default function timeSheet(state = defaultState, action) {
  switch (action.type) {
    case TYPES.TIMESHEETS:
      return {
        ...state,
        timeSheets: action.value
      };
    case TYPES.COUNT_TIMESHEETS:
      return {
        ...state,
        count: action.value
      };
    default:
      return state;
  }
}
