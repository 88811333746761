import * as TYPES from "./dispatchActionTypes";

const defaultState = {
  count: 0,
  dispatches: [],
  dispatchesNext: [],
  dispatchBusy: false,
  selectedDispatch: {},
  selectedDispatchShipper: {}
};

export default function dispatch(state = defaultState, action) {
  switch (action.type) {
    case TYPES.DISPATCH_COUNT:
      return {
        ...state,
        count: action.value
      };
    case TYPES.DISPATCHES:
      return {
        ...state,
        dispatches: action.value
      };
    case TYPES.DISPATCHES_NEXT:
      return {
        ...state,
        dispatchesNext: action.value
      };
    case TYPES.SELECTED_DISPATCH:
      return {
        ...state,
        selectedDispatch: action.value
      };
    case TYPES.SELECTED_DISPATCH_SHIPPER:
      return {
        ...state,
        selectedDispatchShipper: action.value
      };
    case TYPES.DISPATCH_SMS:
      return {
        ...state,
        dispatchSms: action.value
      };
    case TYPES.DISPATCH_UPDATES:
      return {
        ...state,
        dispatchUpdates: action.value
      };
    case TYPES.BUSY_DISPATCH:
      return {
        ...state,
        dispatchBusy: action.value
      };
    default:
      return state;
  }
}
