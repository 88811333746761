import * as TYPES from "./smsActionTypes";

const defaultState = {
  sms: [],
  count: 0
};

export default function sms(state = defaultState, action) {
  switch (action.type) {
    case TYPES.SMS:
      return {
        ...state,
        sms: action.value
      };
    case TYPES.SMS_COUNT:
      return {
        ...state,
        count: action.value
      };
    default:
      return state;
  }
}
