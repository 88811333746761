
import * as TYPES from "./availabilityActionTypes";

const defaultState = {
  count: 0,
  availabilites: [],
  selAvailability: {}
};

export default function availability(state = defaultState, action) {
  switch (action.type) {
    case TYPES.AVAILABILIES:
      return {
        ...state,
        availabilites: action.value
      };
    case TYPES.AVAILABILITY_COUNT:
      return {
        ...state,
        count: action.value
      };
    case TYPES.AVAILABILITY_SELECTED:
      return {
        ...state,
        selAvailability: action.value
      };
    default:
      return state;
  }
}
