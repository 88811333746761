import * as firebase from "firebase/app";
import "firebase/messaging";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  // databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  // storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
};

firebase.initializeApp(firebaseConfig);

let messaging;
try {
  messaging = firebase.messaging();
  messaging.usePublicVapidKey(process.env.REACT_APP_FIREBASE_CERT);
} catch (e) {
  console.log("e ====== ", e);
}

// messaging
//   .requestPermission()
//   .then(async function() {
//     const token = await messaging.getToken();
//     console.log("token :::::>> ", token);
//     // setToken(token);
//   })
//   .catch(function(err) {
//     console.log("Unable to get permission to notify.", err);
//   });

// messaging.onMessage(payload => {
//   console.log("Message received. ", payload);
//   // ...
// });
export default messaging;
